import React, {
  createContext, ReactNode, useEffect, useMemo, useState,
} from 'react';
import envVariables from '../envVariables';
import getSiteTerms from '../graphQL/helpers/getSiteTerms';

export type NavigationContext = {
  paths: IPaths
  isCourseSite: boolean,
  isEventsSite: boolean,
  isAdultSite: boolean,
  isChildSite: boolean
}

interface TermInfo {
  id: string, name: string, url: string
}
interface IPaths {
  root: string,
  courses: string,
  events: string,
  adultCourses: string,
  childrenCourses: string,
  adultTerms: TermInfo[],
  childTerms: TermInfo[]
}

type INavProviderProps = {
  children: ReactNode
}

const NavigationContext = createContext<NavigationContext>(undefined);

const NavigationProvider = ({ children }: INavProviderProps): any => {
  const {
    SITE, SITE_ID, ADULTS_HOST, CHILD_HOST, EVENTS_HOST, LOCALHOST,
  } = envVariables;

  const [adultTerms, setAdultTerms] = useState([]);
  const [childTerms, setChildTerms] = useState([]);

  useEffect(() => {
    const getTermsData = async () => {
      try {
        const termsData = await getSiteTerms();
        const adultSite = termsData.sites.find((site) => site.name === 'UKCPA');
        const childSite = termsData.sites.find((site) => site.name === 'CATS');
        const adultTermData = adultSite ? adultSite.terms.map((term) => ({ ...term, ...{ url: `${siteProtocol}://${ADULTS_HOST}/terms/${term.id}` } })) : [];
        const childTermData = childSite ? childSite.terms.map((term) => ({ ...term, ...{ url: `${siteProtocol}://${CHILD_HOST}/terms/${term.id}` } })) : [];
        setAdultTerms(adultTermData);
        setChildTerms(childTermData);
      } catch (error) {
        console.error('Error fetching site terms:', error);
      }
    };
    getTermsData();
  }, []);

  const isChildSite = SITE_ID === 'CATS';
  const isAdultSite = SITE_ID === 'UKCPA';
  const isEventsSite = SITE === 'EVENTS';
  const isCourseSite = isChildSite || isAdultSite;
  const siteHost = SITE_ID === 'CATS' ? CHILD_HOST : ADULTS_HOST;

  const siteProtocol = LOCALHOST ? 'http' : 'https';

  const paths: IPaths = {
    root: isEventsSite ? '/events-booking' : '/class-booking',
    courses: isCourseSite ? '/class-booking' : `https://${siteHost}`,
    events: isEventsSite ? '/events-booking' : `https://${EVENTS_HOST}`,
    adultCourses: `${siteProtocol}://${ADULTS_HOST}/class-booking`,
    childrenCourses: `${siteProtocol}://${CHILD_HOST}/class-booking`,
    adultTerms,
    childTerms,
  };

  const navProps = useMemo(() => ({
    paths,
    isCourseSite,
    isEventsSite,
    isChildSite,
    isAdultSite,
  }), [adultTerms, childTerms]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <NavigationContext.Provider value={navProps}>
      {children}
    </NavigationContext.Provider>
  );
};

export { NavigationProvider, NavigationContext };
