import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useMemo,
} from 'react';
import { useRouter } from 'next/router';
import {
  IUser,
  Maybe,
} from '../../lib/graphql';

// --- Type Definitions ---

type IMeUser = Pick<IUser, 'id' | 'firstName' | 'lastName' | 'email' | 'profileImageUrl' | 'stripeCustomerId' | 'roles'> & {
  address?: Maybe<{
    __typename?: 'Address';
    line1: string;
    line2: string;
    city: string;
    county: string;
    country: string;
    postCode: string;
  }>;
};

type AuthContextType = {
  user: IMeUser | null | undefined;
  loading: boolean;
  errors: unknown[];
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;
};

type IUserProviderProps = {
  children: ReactNode;
};

// --- Context Creation ---

const UserContext = createContext<AuthContextType | undefined>(undefined);

// --- User Provider ---

const UserProvider = ({ children }: IUserProviderProps): JSX.Element => {
  const { pathname } = useRouter();
  const [user, setUser] = useState<AuthContextType['user']>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<unknown[]>([]);

  // --- Helper Functions ---

  const handleApiError = (err: unknown) => {
    console.error(err);
    setErrors([err]);
  };

  const handleApiResponse = async (response: Response) => {
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'API request failed');
    }
    return response.json();
  };

  // --- Authentication Functions ---

  const login = async (email: string, password: string): Promise<boolean> => {
    setLoading(true);
    try {
      const response = await fetch('/api/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ email, password }),
      });

      const resp = await handleApiResponse(response);
      if (resp.graphQLErrors) {
        setErrors(resp.graphQLErrors);
        throw new Error(resp.message);
      }
      await getUser();
      return true;
    } catch (err) {
      handleApiError(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const logout = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await fetch('/api/auth/logout', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });

      await handleApiResponse(response);
      setUser(null);
    } catch (err) {
      handleApiError(err);
    } finally {
      setLoading(false);
    }
  };

  const getUser = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await fetch('/api/auth/me', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });

      const resp = await handleApiResponse(response);
      if (!resp.data) throw new Error('No user data returned');

      const { data } = resp;
      const profile = data.me?.user;
      setUser(profile || null);
    } catch (err) {
      handleApiError(err);
    } finally {
      setLoading(false);
    }
  };

  // --- Effects ---

  useEffect(() => {
    getUser();
  }, [pathname]);

  // --- Context Value ---

  const contextValue = useMemo<AuthContextType>(() => ({
    user,
    loading,
    errors,
    login,
    logout,
  }), [user, loading, errors]);

  // --- Render ---

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

// --- Custom Hook ---

const useAuth = (): AuthContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useAuth must be used within a UserProvider');
  }
  return context;
};

export { UserProvider, useAuth };
